import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState, useEffect } from "react";
import React from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

export default function Modal() {
	let [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		setIsOpen(localStorage.getItem("business-modal-seen") !== "true");
	}, []);

	function closeModal() {
		if (typeof window !== "undefined") {
			localStorage.setItem("business-modal-seen", true);
			setIsOpen(false);
		}
	}

	return (
		<>
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as="div" className="relative z-10" onClose={closeModal}>
					{/* <div
						className="fixed inset-0 bg-black/50 backdrop-blur"
						aria-hidden="true"
					/> */}

					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<button
							type="button"
							className="p-4 absolute top-5 right-5"
							onClick={() => {
								closeModal();
							}}
						>
							<XMarkIcon
								className="h-8 w-8 text-white"
								aria-hidden="true"
							/>
						</button>

						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-off-white text-gray p-6 text-left align-middle shadow-xl transition-all">
									<div className="text-center">
										<Dialog.Title
											as="h3"
											className="text-2xl font-extrabold text-gray-900"
										>
											Benieuwd naar ons zakelijk
											portfolio?
										</Dialog.Title>
										<div className="mt-2">
											<p className="text-sm">
												Sindskort vind je ons zakelijk
												portfolio bij Ambassade32.
											</p>
										</div>
									</div>

									<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
										<button
											type="button"
											className="inline-flex w-full justify-center rounded-md  border border-gray text-gray hover:text-off-white hover:bg-gray px-4 py-2 text-base font-medium shadow-sm sm:col-start-2 sm:text-sm"
											onClick={closeModal}
										>
											Blijf bij SPARK
										</button>
										<a
											href="https://ambassade32.nl?utm_source=wemakeitspark&utm_medium=modal&utm_campaign=spark-en-ambassade32"
											target="_blank"
											rel="noreferrer"
											type="button"
											className="mt-3 inline-flex w-full justify-center rounded-md bg-gray text-off-white border border-gray px-4 py-2 text-base font-medium hover:text-gray shadow-sm hover:bg-off-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
										>
											Ga naar AMBASSADE32
										</a>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	);
}
