import * as React from "react";
import Hero from "../components/hero";
import Intro from "../components/intro";
import Culture from "../components/culture";
import Services from "../components/services";
import CaseCarousel from "../components/casecarousel";
import Layout from "../components/layout";
import Modal from "../components/modal";
import { useStaticQuery, graphql } from "gatsby";
import { useCaseOverview } from "../hooks/use-case-overview";
import { Seo } from "../components/seo";

const IndexPage = () => {
	const cases = useCaseOverview();

	const data = useStaticQuery(graphql`
		query {
			directus {
				hero_images(filter: { location: { _eq: "index" } }) {
					image {
						id
						imageFile {
							childImageSharp {
								gatsbyImageData(
									height: 900
									quality: 75
									placeholder: BLURRED
								)
							}
						}
					}
				}
				services(filter: { status: { _eq: "published" } }) {
					id
					summary
					title
					href
					cover {
						id
						imageFile {
							id
							childImageSharp {
								gatsbyImageData(
									width: 600
									quality: 75
									placeholder: BLURRED
								)
							}
						}
					}
				}
			}
		}
	`);

	return (
		<>
			<Layout navbarDark={true}>
				<main className="divide-y divide-off-white">
					<Hero image={data.directus.hero_images[0]} />
					<Intro />
					<CaseCarousel cases={cases} />
					<Culture />
					<Services services={data.directus.services} />
					<Modal />
				</main>
			</Layout>
		</>
	);
};

export default IndexPage;

export const Head = () => <Seo />;
