import React from "react";
import Button from "./button";
import Heading from "./heading";
import Stamp from "./stamp";

const Culture = () => {
	return (
		<section className="bg-gray">
			<div className="bg-off-white py-36 px-12 relative">
				<Stamp className="max-w-[75vw] w-96 absolute bottom-8 left-8 z-0" />

				<div className="relative z-10 max-w-7xl mx-auto">
					<Heading
						className="mb-16"
						size="text-5xl md:text-7xl xl:text-8xl"
					>
						<span className="stroked--dark xl:block mr-3">
							We push the{" "}
							<span className="cancel-stroke">pause</span>
						</span>
						<span className="stroked--dark xl:text-right xl:block">
							<span className="cancel-stroke">button</span> of
							life
						</span>
					</Heading>

					<div className="max-w-4xl mx-auto">
						<p>
							Een goede fotograaf kan een goede foto maken, maar
							een emotie vastleggen vergt meer dan dat. Dat lukt
							pas als je die emotie zelf ook kunt voelen. Als je
							zelf ook geraakt wil worden. Bij Spark werken we
							enkel met gepassioneerde schatzoekers. Pas als we
							dat geluksgevoel kunnen laten (her)beleven door een
							foto, is onze missie geslaagd.
						</p>

						<Button to="/cultuur" dark={true} className="mt-8">
							Bekijk ons verhaal
						</Button>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Culture;
