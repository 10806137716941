import React from "react";
import SparkStamp from "../images/spark_stamp_white.svg";

export default function Stamp({ ...rest }) {
	return (
		<div {...rest}>
			<SparkStamp />
		</div>
	);
}
