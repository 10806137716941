import React from "react";
import SparkLogo from "../images/spark_logo_white.svg";
import Button from "../components/button";
import Stamp from "./stamp";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useSiteMetadata } from "../hooks/use-site-metadata";

const Hero = ({ image }) => {
	const { title } = useSiteMetadata();

	return (
		<section className="md:h-[calc(100vh-112px)] md:divide-x divide-y md:divide-y-0 divide-off-white bg-off-white md:flex">
			<div className="h-[calc(90vh-112px)] md:h-auto bg-gray md:w-7/12 p-8 md:p-24 relative flex items-center justify-center rounded-spark-b md:rounded-spark-r">
				<div className="rounded-spark-r">
					<h2 className="text-zinc-700 mb-2 text-sm md:text-base lg:text-lg">
						{title}
					</h2>
					<h1 className="text-6xl lg:text-8xl xl:text-9xl font-bold text-white">
						<span className="stroked mr-5 md:mr-10">
							It starts with a
						</span>
						<SparkLogo
							className="fill-white inline h-16 lg:h-24 xl:h-32 mt-2"
							alt="Spark"
						/>
					</h1>

					<Button to="/portfolio" className="mt-12">
						Bekijk ons portfolio
					</Button>
				</div>

				<Stamp className="w-20 md:w-24 absolute top-8 md:top-auto md:bottom-8 right-8" />
			</div>
			<div className="md:w-5/12 bg-off-white">
				<GatsbyImage
					imgClassName="rounded-spark"
					className="object-cover h-full w-full rounded-spark"
					alt="Spark"
					image={getImage(image.image.imageFile)}
					loading="eager"
				/>
			</div>
		</section>
	);
};

export default Hero;
