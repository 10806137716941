import React from "react";
import Button from "./button";
import Heading from "./heading";
import ServiceItem from "./service-item";

const Services = ({ services }) => {
	return (
		<section className="bg-gray">
			<div className="bg-white py-36 px-8">
				<div className="max-w-4xl mx-auto">
					<Heading as="h2" size="text-4xl md:text-5xl">
						Catching those specials things in life, in a very
						special way
						<Button
							to="/portfolio"
							dark={true}
							buttonClassName="pt-0"
						>
							Bekijk ons werk
						</Button>
					</Heading>
				</div>

				<div className="max-w-6xl mx-auto my-28">
					<div className="grid md:grid-cols-2 lg:grid-cols-3 gap-12 ">
						{services.map((item) => (
							<ServiceItem
								key={item.id}
								title={item.title}
								image={item.cover.imageFile}
								url={item.href}
							>
								{item.summary}
							</ServiceItem>
						))}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Services;
