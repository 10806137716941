import React from "react";
import Button from "./button";
import Heading from "./heading";

const Intro = () => {
	return (
		<section className="bg-gray text-off-white flex justify-center py-36 px-8">
			<div className="text-center max-w-4xl">
				<Heading as="h2" size="text-3xl md:text-5xl" className="mb-8">
					"Hier had ik bij willen zijn", is ons grootste compliment.
				</Heading>
				<p className="text-md md:text-xl">
					Bij Spark creëren we het FOMO-gevoel en vereeuwigen we de
					momenten die nooit verloren mogen gaan. Als jouw
					strategische beeldpartner bedenken, verspreiden en maken we
					content die blijft plakken in een wereld waar aandacht
					vluchtig is.
				</p>
				<Button to="/contact" className="mt-8">
					Contact
				</Button>
			</div>
		</section>
	);
};

export default Intro;
