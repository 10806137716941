import React from "react";
import Heading from "./heading";
import Button from "./button";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ServiceItem = ({ title, image, url, children }) => {
	return (
		<div className="flex-1">
			<div className="aspect-3/2">
				<GatsbyImage
					image={getImage(image)}
					className="object-cover h-full w-auto rounded-spark"
					imgClassName="rounded-spark"
					alt={title}
				/>
			</div>
			<Heading as="h4" className="mt-8 mb-4">
				{title}
			</Heading>
			<p>{children}</p>
			{url && (
				<Button to={url} dark={true} className="mt-4">
					Bekijk {title}
				</Button>
			)}
		</div>
	);
};

export default ServiceItem;
